<template>
  <v-container>
    <perseu-card title="Plano detalhado">
      <template #content>
        <v-form ref="form">
          <v-switch label="Publicamente visível" v-model="plan.publicVisible" />
          <v-switch
            label="Plano recomendado?"
            v-model="plan.isRecommended"
            hint="Irá mostrar em destaque como plano recomendado na listagem de planos no APP"
          />
          <v-text-field
            :rules="[$rules.required]"
            v-model="plan.idPaypal"
            label="ID Paypal"
          />
          <v-text-field
            :rules="[$rules.required]"
            v-model="plan.name"
            label="Nome"
          />
          <v-text-field v-model="plan.description" label="Descrição" />
          <v-select
            :items="types"
            item-value="value"
            item-text="label"
            :rules="[$rules.required]"
            v-model="plan.productType"
            label="Tipo do produto"
          />
          <perseu-input-image-aws
            v-model="plan.image"
            class="my-5"
            label="Imagem"
          />
          <v-text-field
            v-model="plan.price"
            label="Valor mensal"
            readonly
            disabled
          />
          <v-text-field
            v-model="plan.status"
            label="Status"
            readonly
            disabled
          />
          <v-text-field
            :value="plan.expirationDate | formatDate"
            label="Data de expiração"
            readonly
            disabled
          />
        </v-form>
      </template>
      <template #actions>
        <v-btn outlined @click="$router.go(-1)"> Voltar</v-btn>
        <v-btn
          @click="redirectToPlanUsersEmail"
          color="primary"
          :disabled="!plan.idPaypal"
        >
          Gerenciar e-mails
        </v-btn>
        <v-btn color="secondary" @click="createOrUpdate">Salvar</v-btn>
      </template>
    </perseu-card>
    <benefits-section
      class="my-3"
      :benefits="plan.benefits"
      :id-plan="plan.idPaypal"
      @add-benefit="addBenefit"
      v-if="plan.price"
      @update-benefit="updateBenefit"
      @remove-benefit="removeBenefit"
    />
  </v-container>
</template>

<script>
import {
  getProductsType,
  getOnePlan,
  putPlan,
  postPlan,
} from "@/services/paypal-service";
import { pagesNames } from "@/router/pages-names";

export default {
  name: "Plan",
  components: {
    PerseuInputImageAws: () =>
      import("@/components/shared/PerseuInputImageAWS"),
    BenefitsSection: () => import("@/components/Paypal/BenefitsSection"),
  },
  data: () => ({
    plan: {
      benefits: [],
      plans: [],
    },
    types: [],
  }),
  created() {
    this.fillSelectedPlan();
    this.loadTypes();
  },

  methods: {
    async fillSelectedPlan() {
      if (this.$route.query.idPaypal) {
        this.$store.dispatch("loading/openDialog");
        const { data } = await getOnePlan(this.$route.query.idPaypal);
        this.plan = data;
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async loadTypes(planId) {
      this.$store.dispatch("loading/openDialog");
      const { data } = await getProductsType(planId);
      this.types = data;
      this.$store.dispatch("loading/closeDialog");
    },
    async createOrUpdate() {
      try {
        this.$store.dispatch("loading/openDialog");
        if (!this.$refs.form.validate()) return;
        let response;
        if (this.plan.idPaypal) {
          response = await this.update();
        } else {
          response = await this.create();
        }
        this.plan = { ...this.plan, ...response };
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async create() {
      const { data } = await postPlan(this.plan);
      return data;
    },
    async update() {
      const { data } = await putPlan(this.plan);
      return data;
    },
    addBenefit({ benefit }) {
      this.plan.benefits.push(benefit);
    },
    findIndexBenefit(id) {
      return this.plan.benefits.findIndex((benefit) => benefit.id === id);
    },
    removeBenefit({ idBenefit }) {
      const foundIndex = this.findIndexBenefit(idBenefit);
      this.plan.benefits.splice(foundIndex, 1);
    },
    updateBenefit({ benefit }) {
      const foundIndex = this.findIndexBenefit(benefit.id);
      this.plan.benefits.splice(foundIndex, 1, benefit);
    },
    redirectToPlanUsersEmail() {
      this.$router.push({
        name: pagesNames.PAYPAL_PLAN_USERS_EMAIL,
        query: { planId: this.plan.idPaypal, planName: this.plan.name },
      });
    },
  },
};
</script>

<style scoped></style>
