import api from "./api-service";
import querystring from "querystring";

const basePath = "paypal";

export const getPlans = () => api().get(`${basePath}/plans`);

export const getOnePlan = (id) => api().get(`${basePath}/plans/${id}`);

export const getProductsType = (id) => api().get(`${basePath}/products/types`);

export const postPlan = (plan) => api().post(`${basePath}/plans`, plan);

export const putPlan = (plan) => api().put(`${basePath}/plans`, plan);

export const createBenefit = (idPaypalPlan, benefit) =>
  api().post(`${basePath}/plans/benefits/${idPaypalPlan}`, benefit);

export const updateBenefit = (idPaypalPlan, benefit) =>
  api().put(`${basePath}/plans/benefits/${idPaypalPlan}`, benefit);

export const deleteBenefit = (idBenefit) =>
  api().delete(`${basePath}/plans/benefits/${idBenefit}`);

export const syncPlanWithPaypal = ({ idPaypalPlan, idPaypal }) =>
  api().post(`${basePath}/plans/sync`, { idPaypalPlan, idPaypal });

export const addUserEmailToPlan = ({ email, planId }) =>
  api().post(`${basePath}/plan-users-email`, { email, planId });

export const deleteUserEmailOfPlan = (id) =>
  api().delete(`${basePath}/plan-users-email/${id}`);

export const findAllUsersEmailByPlanId = (planId) =>
  api().get(`${basePath}/plan-users-email/${planId}`);
